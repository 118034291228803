<template>
  <div v-if="!loading" class="media-poster-wrapper">
    <!-- MEDIA POSTER -->
    <div class="media-poster">
      <!-- POSTER SIZER -->
      <img width="350" src="./assets/img/poster-sizer--350x525.svg" alt="Media Poster" />

      <!-- POSTER INNER -->
      <div class="media-poster-inner">
        <!-- POSTER BODY -->
        <div class="media-poster-body">
          <!-- BG FILL -->
          <span
            class="bg-fill"
            :style="{ backgroundImage: 'url(' + mediaPosterImage + ')' }"
          ></span>
        </div>
      </div>

      <!-- POSTER ATTRIBUTION -->
      <slot />
    </div>
  </div>
</template>

<script>
  import tmdbConfig from "@/config/tmdb";
  import getHardcodedPosterPath from "./getHardcodedPosterPath.helper";

  export default {
    props: {
      author: {
        type: String,
        required: false,
      },
      dataObject: {
        type: Object,
        required: true,
      },
      path: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
        mediaObj: null,
        mediaPosterImage: null,
      };
    },
    mounted() {
      const posterName = this.path.toLowerCase().trim();
      const override = getHardcodedPosterPath(posterName);

      if (override) {
        this.setPosterImage(override);
      } else {
        this.fetchPosterData(posterName, this.type);
      }
    },
    methods: {
      //-----------------------------------------------------------------
      // SET POSTER IMAGE
      //-----------------------------------------------------------------

      setPosterImage(path) {
        const img = new Image();
        img.crossOrigin = "anonymous"; // For colorThief.
        img.src = path;

        img.onload = () => {
          this.loading = false;
          this.mediaPosterImage = path;
        };
      },

      //-----------------------------------------------------------------
      // FETCH POSTER DATA
      // fetch twitch channel - consider rename if merge
      //-----------------------------------------------------------------

      fetchPosterData(name = "", type = "") {
        const endpoints = {
          actor: `${tmdbConfig.TMDB_API.searchActor}/${encodeURIComponent(name)}`,
          movie: `${tmdbConfig.TMDB_API.searchMovie}/${encodeURIComponent(name)}`,
        };

        const url = endpoints[type];

        fetch(url, { credentials: "include" })
          .then((res) => res.json())
          .then((data) => {
            const dataObj = data[0];

            if (dataObj) {
              this.mediaObj = dataObj;

              // console.log('Movie data:', dataObj);

              const fileName = dataObj.poster_path || dataObj.profile_path;
              const path = `https://image.tmdb.org/t/p/w500/${fileName}`;

              this.setPosterImage(path);

              // SAY MESSAGE - "SUCCESS" -- TODO, after server rewrite - this needs to come from the TMS api!!
              // this.$store.dispatch('chatbot/sayMessage', {
              // 	color: 'SpringGreen',
              // 	user: this.author,
              // 	message: type === 'movie'
              // 		?
              // 			"themit10Head ${user} has a movie on their mind. 🎬"
              // 		: 	"themit10Head ${user} is thinking about that person in that thing that time. 🎬"
              // });
            } else {
              // console.warn('Oops! No user was found with that name.');
              // REFUND USER POINTS
              // this.$store.dispatch('chatbot/refundUserPoints', this.dataObject);
              // SAY MESSAGE - "FAIL"
              // this.$store.dispatch('chatbot/sayMessage', {
              // 	color: 'Red',
              // 	user: this.author,
              // 	message: "@${user}, oops! We couldn't find a match for that. ❌"
              // });
            }
          })
          .catch((error) => {
            console.error("Oops! Poster didn't load.", error);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");

  //-----------------------------------------------------------------
  // MEDIA POSTER WRAPPER - HAS VUE TRANSITION
  //-----------------------------------------------------------------

  .media-poster-wrapper {
    position: absolute;
    top: rem-calc(100); // [LOCKED]
    left: rem-calc(290); // [LOCKED]
    // border: 2px dashed red;
  }

  //-----------------------------------------------------------------
  // MEDIA POSTER
  //-----------------------------------------------------------------

  .media-poster {
    position: relative;
    padding: rem-calc(8); // [LOCKED] - extends card out
    background-color: white; //#f6eac7; //#e1dfdd;
    // border: 1px dashed orange;
    box-shadow: 0 10px 50px 20px rgba(black, 0.8);
    // border-radius: rem-calc(20);
    transform: perspective(400px) rotateY(10deg);
    animation: float 3s infinite linear;

    @keyframes float {
      from {
        transform: rotate(0deg) translate3d(3px, 0, 0) rotate(0deg) perspective(400px)
          rotateY(10deg);
      }
      to {
        transform: rotate(360deg) translate3d(3px, 0, 0) rotate(-360deg) perspective(400px)
          rotateY(10deg);
      }
    }

    // IVORY TEXTURE
    // &:after {
    // 	content: "";
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	width: 100%;
    // 	height: 100%;
    // 	// border-radius: rem-calc(20);
    // 	background: url('./assets/img/texture-ivory@2x.png') no-repeat center center;
    // 	background-size: cover;
    // 	mix-blend-mode: multiply;
    // 	z-index: 1;
    // }
  }

  .is-performance-mode .media-poster {
    animation: none;
  }

  //-----------------------------------------------------------------
  // MEDIA POSTER INNER (THE FRAME)
  //-----------------------------------------------------------------

  .media-poster-inner {
    display: flex;
    flex-direction: column;
    position: absolute;
    $pad: rem-calc(10); // 18
    top: $pad;
    left: $pad;
    width: calc(100% - #{$pad * 2});
    height: calc(100% - #{$pad * 2});
    // border: 4px solid #264691;
    // border-radius: rem-calc(15);
    // box-shadow: inset 0 2px 5px 3px rgba(black, 0.2);
    background-color: #363840;
    overflow: hidden;

    // WORN TEXTURE
    // &:after {
    // 	content: "";
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	width: 100%;
    // 	height: 100%;
    // 	background: url('./assets/img/texture-worn@2x.png') no-repeat center center;
    // 	background-size: contain;
    // 	z-index: 1;
    // }
  }

  //-----------------------------------------------------------------
  // POSTER INNER (OLD)
  //-----------------------------------------------------------------

  .bg-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    // background-size: 350px;
    background-size: cover; // new untested
  }

  //-----------------------------------------------------------------
  //
  //-----------------------------------------------------------------
</style>
