<template>
	<div
		v-if="!loading"
		class="media-poster-wrapper"
	>
		<!-- MEDIA POSTER -->
		<div class="media-poster">

			<!-- POSTER SIZER -->
			<img width="400" src="./assets/img/poster-sizer--640x640.svg" alt="Media Poster">

			<!-- POSTER INNER -->
			<div class="media-poster-inner">

				<!-- POSTER BODY -->
				<div class="media-poster-body">

					<!-- BG FILL -->
					<span class="bg-fill" :style="{ backgroundImage: 'url('+ mediaPosterImage +')' }"></span>

				</div>

			</div>

			<!-- POSTER ATTRIBUTION -->
			<slot />

		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import tmdbConfig from '@/config/tmdb.js';

	export default {
		props: {
			author: {
				type: String,
				required: false
			},
			dataObject: {
				type: Object,
				required: true
			},
			path: {
				type: String,
				required: true
			},
			type: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				loading: true,
				mediaObj: null,
				mediaPosterImage: null,
			}
		},
		mounted() {
			// INIT
			const val = this.path.toLowerCase().trim();

			this.fetchPosterData(val, this.type);
		},
		methods: {

			//-----------------------------------------------------------------
			// SET POSTER IMAGE
			//-----------------------------------------------------------------

			setPosterImage(path) {

				// Prepare main image
				const img = new Image();
					  img.crossOrigin = 'anonymous'; // For colorThief.
					  img.src = path;

				img.onload = () => {
					this.loading = false;
					this.mediaPosterImage = path;
					// console.log('success!', path);
				}
			},

			//-----------------------------------------------------------------
			// FETCH POSTER DATA
			// fetch twitch channel - consider rename if merge
			//-----------------------------------------------------------------

			fetchPosterData(name, type) {

				const url = process.env.VUE_APP_SERVER_ORIGIN + '/api/v1/media-search/album/' + name;

				fetch(url, { credentials: "include"})
				.then(res => res.json())
				.then(data => {

					const dataObj = data.data;

					if (dataObj.length) {

						// Store data.
						this.mediaObj = dataObj[0];

						console.log('Movie data:', dataObj);

						const path = dataObj[0].poster_path

						// SET IT
						this.setPosterImage(path);

						// SAY MESSAGE - "SUCCESS"
						// this.$store.dispatch('chatbot/sayMessage', {
						// 	color: 'SpringGreen',
						// 	user: this.author,
						// 	message: "themit10Head ${user} has an album on their mind. 💿"
						// });

					} else {
						// console.warn('Oops! No user was found with that name.');

						// REFUND USER POINTS
						// this.$store.dispatch('chatbot/refundUserPoints', this.dataObject);

						// SAY MESSAGE - "FAIL"
						// this.$store.dispatch('chatbot/sayMessage', {
						// 	color: 'Red',
						// 	user: this.author,
						// 	message: "@${user}, oops! We couldn't find a match for that. ❌"
						// });
					}
				})
				.catch(error => {
					console.error("Oops! Poster didn't load.", error);
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

	//-----------------------------------------------------------------
	// MEDIA POSTER WRAPPER - HAS VUE TRANSITION
	//-----------------------------------------------------------------

	.media-poster-wrapper {
		position: absolute;
		top: rem-calc(180); // [LOCKED]
		left: rem-calc(290); // [LOCKED]
		// border: 2px dashed red;
	}

	//-----------------------------------------------------------------
	// MEDIA POSTER
	//-----------------------------------------------------------------

	.media-poster {
		position: relative;
		padding: rem-calc(8); // [LOCKED] - extends card out
		background-color: white; //#f6eac7; //#e1dfdd;
		// border: 1px dashed orange;
		box-shadow: 0 10px 50px 20px rgba(black, 0.8);
		// border-radius: rem-calc(20);
		// transform: perspective(400px) rotateY(10deg);
		animation: float 3s infinite linear;

		@keyframes float {
			from { transform: rotate(0deg)   translate3d(3px, 0, 0) rotate(0deg)    perspective(400px) rotateY(10deg); }
			to   { transform: rotate(360deg) translate3d(3px, 0, 0) rotate(-360deg) perspective(400px) rotateY(10deg);  }
		}

		// IVORY TEXTURE
		&:after {
			// content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			// border-radius: rem-calc(20);
			// background: url('./assets/img/texture-ivory@2x.png') no-repeat center center;
			// background: url('./assets/img/cd-plastic@2x.gif') no-repeat center center;


			background-size: cover;
			mix-blend-mode: multiply;
			z-index: 1;
		}
	}

	//-----------------------------------------------------------------
	// MEDIA POSTER INNER (THE FRAME)
	//-----------------------------------------------------------------

	.media-poster-inner {
		display: flex;
		flex-direction: column;
		position: absolute;
		$pad: 0; //rem-calc(0); // 10
		top: 0; //$pad;
		left: 0; //$pad;
		// width: calc(100% - #{$pad*2});
		// height: calc(100% - #{$pad*2});

		width: 100%;
		height: 100%;

		// border: 4px solid #264691;
		// border-radius: rem-calc(15);
		// box-shadow: inset 0 2px 5px 3px rgba(black, 0.2);
		background-color: #363840;
		overflow: hidden;

		// WORN TEXTURE
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			// background: url('./assets/img/texture-worn@2x.png') no-repeat center center;
			// background: url('./assets/img/cd-plastic@2x.gif') no-repeat center center;
			background: url('./assets/img/cd-plastic@2x.gif') no-repeat center center;

			opacity: 0.3;
			transform: scale(1.05);
			// mix-blend-mode: exclusion;

			background-size: contain;
			z-index: 1;
		}
	}

	//-----------------------------------------------------------------
	// POSTER INNER (OLD)
	//-----------------------------------------------------------------

	.bg-fill {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		// background-size: 350px;
		background-size: cover; // new untested
	}

	//-----------------------------------------------------------------
	//
	//-----------------------------------------------------------------

</style>