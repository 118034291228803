<template>
	<div class="media-poster-attribution-parent">

		<!-- POSTER ATTRIBUTION -->
		<h3 class="media-poster-attribution">
			<i class="fa fa-twitch mr-1"></i>
			<span class="text">
				{{ author }}
			</span>
		</h3>
	</div>

</template>

<script>
	export default {
		props: {
			author: {
				type: String,
				required: false
			}
		}
	}
</script>

<style lang="scss" scoped>

	.media-poster-attribution-parent {
		position: absolute;
		left: 0;
		width: 100%;
		top: calc(100% + #{rem-calc(40)});
		// border: 1px dashed aqua;
		text-align: center;
	}

	//-----------------------------------------------------------------
	// POSTER ATTRIBUTION
	//-----------------------------------------------------------------

	.media-poster-attribution {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: relative;
		max-width: 82%;
		min-width: 60%;
		padding: rem-calc(10 20);
		border-radius: 5px;
		margin: 0 auto;
		text-align: center;
		background-color: white;
		// animation: float 3s infinite linear;
		z-index: 1; // [LOCKED]

		@keyframes float {
			from { transform: rotate(0deg)   translate3d(2px, 0, 0) rotate(0deg)    rotateY(10deg); }
			to   { transform: rotate(360deg) translate3d(2px, 0, 0) rotate(-360deg) rotateY(10deg);  }
		}

		&:after {
			content: "";
			position: absolute;
			top: -10px;
			left: calc(50% - #{rem-calc(30/2)});
			width: rem-calc(30);
			height: rem-calc(30);
			border-radius: 3px;
			background: inherit;
			transform: rotate(45deg);
			z-index: -1;
		}

		.text {
			font-size: rem-calc(28);
			font-weight: 600;
			color: #222;
			// word-break: break-all;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.fa {
			font-size: rem-calc(28);
			color: #9147ff;
		}
	}
</style>