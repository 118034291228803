/* eslint-disable global-require */

/**
 * GET HARDCODED POSTER PATH
 */
export default function getHardcodedPosterPath(val = "") {
  // AMERICAN PSYCHO
  if (val === "american psycho" || val === "psycho" || val === "bale" || val === "christian bale") {
    return require("./assets/img/posters/gifs/christian-bale.gif");
  }

  // BACK TO THE FUTURE
  if (val === "back to the future" || val === "marty mcfly") {
    return require("./assets/img/posters/back-to-the-future.jpg");
  }

  // BACON MASTER
  if (val === "theonlybaconmaster" || val === "bacon" || val === "tyler") {
    return require("./assets/img/posters/the-only-bacon-master.jpg");
  }

  // BIG
  if (val === "big" || val === "tom hanks" || val === "tom") {
    return require("./assets/img/posters/big.jpg");
  }

  // BOB THE BUTLER
  if (val === "bob the butler" || val === "butler bob" || val === "butler the bob") {
    return require("./assets/img/posters/bob-the-butler.jpg");
  }

  // BRUNO
  if (val === "bruno") {
    return require("./assets/img/posters/bruno.jpg");
  }

  // CHOPS
  if (val === "chops" || val === "chopstalk") {
    return require("./assets/img/posters/chops.jpg");
  }

  // CHRIS EVANS
  if (val === "chris evans") {
    return require("./assets/img/posters/gifs/chris-evans.gif");
  }

  // A CHRISTMAS STORY
  if (val.includes("christmas story")) {
    return require("./assets/img/posters/a-christmas-story.jpg");
  }

  // DIE HARD
  if (val === "die hard") {
    return require("./assets/img/posters/die-hard.jpg");
  }

  // ELBOW
  if (val.includes("elbow") || val.includes("jeniffer")) {
    // Jeniffer Elbow
    return require("./assets/img/posters/elbow.jpg");
  }

  // ELF
  if (val === "elf") {
    return require("./assets/img/posters/elf.jpg");
  }

  // FAIZY
  if (val === "faizy" || val === "faiz" || val === "faizan" || val === "faizankhan1234") {
    return require("./assets/img/posters/faizy-4.jpg");
  }

  // FAIZY MERCH
  if (val === "faizymerch" || val === "faizy merch") {
    return require("./assets/img/posters/faizy-5.jpg");
  }

  // FAIZY NOW
  if (val === "faizynow" || val === "faizyadult" || val === "faizygrownup") {
    return require("./assets/img/posters/faizy-grown-up.jpg");
  }

  // FAIZY AS A BIRD
  if (
    val === "faizybird" ||
    val === "birdfaizy" ||
    val === "faizy as a bird" ||
    val === "faiz as a bird" ||
    val === "faizan as a bird" ||
    val === "faizankhan1234 as a bird"
  ) {
    return require("./assets/img/posters/faizy-2.jpg");
  }

  // FAIZY IN A SUIT
  if (
    val === "suitfaizy" ||
    val === "faizysuit" ||
    val === "faizy in a suit" ||
    val === "faiz in a suit" ||
    val === "faizan in a suit" ||
    val === "faizy in a bra" ||
    val === "faizy as a girl" ||
    val === "faizankhan1234 in a suit"
  ) {
    return require("./assets/img/posters/faizy-3.jpg");
  }

  // FRENCH LOLITA
  if (
    val.includes("french lolita") ||
    val.includes("french l") ||
    val.includes("french lo") ||
    val.includes("french lol") ||
    val.includes("french loli") ||
    val.includes("french lolit") ||
    val.includes("lol french") ||
    val.includes("loli french") ||
    val.includes("lolit french") ||
    val.includes("lolita frenc") ||
    val.includes("lolita fren") ||
    val.includes("lolita fre") ||
    val === "french lolita" ||
    val === "french lol"
  ) {
    return require("./assets/img/posters/french-lolita.jpg");
  }

  // FAIZY ON A SWING
  if (val === "faizy on a swing") {
    return require("./assets/img/posters/faizy-on-a-swing.jpg");
  }

  // HILARY DUFF
  if (val === "hilary duff" || val === "duff") {
    return require("./assets/img/posters/gifs/hilary-duff.gif");
  }

  // HOME ALONE 2
  if (val === "home alone 2" || val === "home alone 2 - lost in new york") {
    return require("./assets/img/posters/home-alone-2.jpg");
  }

  // HUEY
  if (val === "huey" || val === "news") {
    return require("./assets/img/posters/huey.png");
  }

  // JAWS
  if (val.includes("jaws")) {
    return require("./assets/img/posters/jaws.jpg");
  }

  // JENNIFER LOVE HEWITT
  if (val.includes("jennifer love") || val === "jlh") {
    return require("./assets/img/posters/jlh-1.jpg");
  }

  // JINGLE ALL THE WAY
  if (val === "jingle all the way") {
    return require("./assets/img/posters/jingle-all-the-way.jpg");
  }

  // JUMANJI
  if (val.includes("jumanji")) {
    return require("./assets/img/posters/jumanji.jpg");
  }

  // JUSTIN
  if (
    val === "justin" ||
    val === "justinjaywood" ||
    val === "justinjwoodlive" ||
    val === "Justinjaywoodlive"
  ) {
    return require("./assets/img/posters/justin-2.jpg");
  }

  // LARRY IRL
  if (val === "larry" || val === "larryirl") {
    return require("./assets/img/posters/larryIRL.jpg");
  }

  // THE GOOD SON
  if (val === "the good son" || val === "good son" || val === "thegoodson") {
    return require("./assets/img/posters/the-good-son.jpg");
  }

  // THE MATRIX
  if (val === "the matrix") {
    return require("./assets/img/posters/the-matrix.jpg");
  }

  // MICHAEL CERA
  if (val === "michael cera" || val === "cera") {
    return require("./assets/img/posters/michael-cera-1.jpg");
  }

  // MITCH
  if (val === "mitch" || val === "mitchell" || val === "themitchinghour" || val === "mitching") {
    return require("./assets/img/posters/mitch-1.jpg");
  }

  // MRS DOUBTFIRE
  if (val === "mrs doubtfire") {
    return require("./assets/img/posters/mrs-doubtfire.jpg");
  }

  // NO
  if (val === "no" || val === "n") {
    return require("./assets/img/posters/no.png");
  }

  // PEDRO PASCAL
  // if (val === "pedro pascal" || val === "pedropascal") {
  //   return require("./assets/img/posters/pedro-pascal-0.jpg");
  // }

  // YES
  if (val === "yes" || val === "y") {
    return require("./assets/img/posters/yes.png");
  }

  // ROAD TRIP
  if (val === "road trip" || val === "roadtrip" || val === "trip road") {
    return require("./assets/img/posters/road-trip.jpg");
  }

  // THE RUNDOWN
  if (val === "the rundown" || val === "rundown") {
    return require("./assets/img/posters/the-rundown.jpg");
  }

  // SAY ANYTHING
  if (val === "say anything") {
    return require("./assets/img/posters/say-anything.jpg");
  }

  // THE SANTA CLAUSE
  if (val === "the santa clause" || val === "the santa claus" || val === "santa") {
    return require("./assets/img/posters/the-santa-clause.jpg");
  }

  // STEALING HARVARD
  if (val === "stealing harvard" || val === "harvard stealing") {
    return require("./assets/img/posters/stealing-harvard.jpg");
  }

  // STUART LITTLE
  if (val === "stuart little") {
    return require("./assets/img/posters/stuart-little.png");
  }

  // THE THING
  if (val === "the thing" || val === "thing") {
    return require("./assets/img/posters/the-thing.jpg");
  }

  // THE WIZARD OF OZ
  if (val === "the wizard of oz" || val === "wizard of oz") {
    return require("./assets/img/posters/the-wizard-of-oz.jpg");
  }

  // THE SOUND OF MUSIC
  if (val === "the sound of music") {
    return require("./assets/img/posters/the-sound-of-music.jpg");
  }

  // THE SILENCE OF THE LAMBS
  if (val === "the silence of the lambs" || val === "silence of the lambs") {
    return require("./assets/img/posters/the-silence-of-the-lambs.jpg");
  }

  // TMATERSHOW
  if (val === "tmatershow" || val === "tmater") {
    return require("./assets/img/posters/tmatershow.jpg");
  }

  // TOM GREEN
  if (val === "tom green" || val === "tomgreen" || val === "green tom") {
    return require("./assets/img/posters/tom-green.jpg");
  }

  return null;
}
