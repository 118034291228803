<template>
	<div class="media-poster-parent">

		<transition
		    enter-active-class="animated zoomInUp"
		    leave-active-class="animated zoomOut"
		    @enter="onEnter"
		    @leave="onLeave"
		    mode="out-in"
		>
			<!-- DYNAMIC COMPONENT -->
			<component
			    :is="component"
				v-if="mediaPosterPath"
				:author="mediaPosterAuthor"
				:key="mediaPosterPath"
			  	:path="mediaPosterPath"
			  	:type="mediaPosterType"
			>
				<!-- ATTRIBUTION -->
				<MediaPosterAttribution
					v-if="mediaPosterAuthor !== 'System'"
					:author="mediaPosterAuthor"
					class="poster-attribution"
				/>

			</component>
		</transition>

	</div>
</template>

<script>
	// https://3dtransforms.desandro.com/box
	// https://codepen.io/pespantelis/pen/RWVZxL
	// https://codepen.io/MattyBalaam/pen/eaJto
	// https://www.thonky.com/javascript-and-css-guide/javascript-image-preload

	import { mapGetters } from 'vuex';
	import MediaPosterAlbum from './MediaPosterAlbum.vue';
	import MediaPosterAttribution from './MediaPosterAttribution.vue';
	import MediaPosterStreamer from './MediaPosterStreamer.vue';
	import MediaPosterMovie from './MediaPosterMovie.vue';

	export default {
		components: {
			MediaPosterAlbum,
			MediaPosterAttribution,
			MediaPosterMovie,
			MediaPosterStreamer
		},
		data() {
			return {
				component: null,
				mediaPosterPath: null
			}
		},
		computed: {
			...mapGetters({
				getActiveTask: 'tasks/getActiveTask',
				isLobby: 'scenes/isLobby',
			}),
			mediaPosterRequest() {
				return this.getActiveTask('mediaPoster', 'Request');
			},
			mediaPosterAuthor() {
				return this.mediaPosterRequest.author;
			},
			mediaPosterType() {
				return this.mediaPosterRequest.data.type;
			}
		},
		watch: {
			mediaPosterRequest: {
				immediate: true,
				handler(newVal, oldVal) {
					if (!!newVal && newVal !== oldVal) {

						// If init value, get Poster for the first time.
						if (newVal.data.arg !== '<name>') {

							this.mediaPosterPath = newVal.data.arg;

							// USE (STREAMER, ALBUM...) COMPONENT
							if (this.mediaPosterType === 'actor') {
								this.component = MediaPosterMovie;
							}
							else if (this.mediaPosterType === 'album') {
								this.component = MediaPosterAlbum;
							}
							else if (this.mediaPosterType === 'movie') { // CHANGE THIS IF ACTOR GETS MADE INTO NEW FILE
								this.component = MediaPosterMovie;
							}
							else if (this.mediaPosterType === 'streamer') {
								this.component = MediaPosterStreamer;
							}

						} else {

							this.mediaPosterPath = '';

							// Cancel task and remove poster after delay. (Delay start doesn't work).
							setTimeout(() => this.$store.dispatch('tasks/runTasks', [{
									uuid: 'a76cb3e3-d186-479d-b345-f9a6598dda87', // mediaPoster
									canceled: true
								}
							]), 2000);
						}
					}
				}
			}
		},
		methods: {
			onEnter() {
				this.$playSound('GUI_Swipe 01', { volume: 0.5, interrupt: true });
			},
			onLeave() {
				this.$playSound('GUI_Swipe 04', { volume: 0.3, interrupt: true });
			}
		}
	}
</script>

<style lang="scss" scoped>

	.poster-attribution {
		animation: little-jump 300ms ease 0.7s alternate 1 forwards;
		// cubic-bezier(1.0, 0.5, 0.8, 1.0);
		opacity: 0;

		@keyframes little-jump {
			from {
				opacity: 0;
				transform: scale(0.7) translateY(40px);
			} to {
				opacity: 1;
				transform: scale(1.0) translateY(0);
			}
		}
	}

	//-----------------------------------------------------------------
	// MEDIA POSTER PARENT
	//-----------------------------------------------------------------

	.media-poster-parent {
		position: absolute;
		top: rem-calc(30);
		left: 0;
		bottom: rem-calc(121); // [LOCKED] - dashboard height
		width: 100%;
		// border: 2px dashed yellow;
		pointer-events: none;
		// z-index: 1; // needed?
	}
</style>